import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import circleImg from '../assets/images/circle.webp';

const useThreeScene = (ref, visible) => {
  const startTime = useRef(Date.now());

  useEffect(() => {
    if (!visible) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, ref.current.clientWidth / ref.current.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(ref.current.clientWidth, ref.current.clientHeight);
    ref.current.appendChild(renderer.domElement);

    const particlesGeometry = new THREE.BufferGeometry();
    const particlesCount = 150;
    const posArray = new Float32Array(particlesCount * 3);

    for (let i = 0; i < particlesCount * 3; i += 3) {
      posArray[i] = (Math.random() - 0.5) * 5 + 1; // X axis
      posArray[i + 1] = (Math.random() - 0.5) * 5 + 1; // Y axis
      posArray[i + 2] = (Math.random() - 0.5) * 5; // Z axis
    }

    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
    const textureLoader = new THREE.TextureLoader();
    const circleTexture = textureLoader.load(circleImg);

    const particlesMaterial = new THREE.PointsMaterial({
      size: 0.05,
      alphaMap: circleTexture,
      // color: new THREE.Color(Math.random() * 0.2 + 0.6, Math.random() * 0.2 + 0.6, Math.random() * 0.2 + 0.8),
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      transparent: true,
      opacity: 0.3, // Start fully transparent
    });

    const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
    scene.add(particlesMesh);

    camera.position.z = 3;

    const animate = () => {
      if (visible) {
        requestAnimationFrame(animate);

        particlesMesh.rotation.x += 0.002;
        particlesMesh.rotation.y += 0.002;

        renderer.render(scene, camera);
      }
    };

    animate();

    return () => {
      if (ref.current) ref.current.removeChild(renderer.domElement);
    };
  }, [ref, visible]);
};

export default useThreeScene;
