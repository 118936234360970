import { useState } from 'react';
import './App.css';
import Card from './Component/Card';

const list = [
  { role: 'ml', name: '梅林', ability: '知道|邪恶势力|身份 不能暴露自己' },
  { role: 'pxwe', name: '派西维尔', ability: '知道|梅林|身份 无法区分真假梅林' },
  { role: 'zc', name: '忠臣', ability: '光明势力' },
  { role: 'zc2', name: '忠臣', ability: '光明势力' },
  { role: 'mgn', name: '莫甘娜', ability: '|冒充|梅林身份 迷惑派西维尔' },
  { role: 'ck', name: '刺客', ability: '光明3次任务成功后 |刺杀|梅林可翻盘' },
  { role: 'mdld', name: '莫德雷德', ability: '对梅林|隐身|' },
  { role: 'zy', name: '爪牙', ability: '邪恶势力' },
  { role: 'abl', name: '奥伯伦', ability: '属于邪恶势力 但看不到|邪恶势力|' }
];

function App() {
  const [openedCardIndex, setOpenedCardIndex] = useState(null);

  const handleCardClick = (index) => {
    // 如果点击的是已经打开的卡片，则关闭
    if (openedCardIndex === index) {
      setOpenedCardIndex(null);
    } else {
      setOpenedCardIndex(index);
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          {list.map((character, index) => (<Card
              key={index}
              character={character}
              onClick={() => handleCardClick(index)}
              isOpen={openedCardIndex === index}
            />)
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
