// src/components/Card.js
import React, { useState, useRef } from 'react';
import './Card.css'; // 确保创建相应的CSS文件
import useThreeScene from './useThreeScene';

// 上述引用img路径全部换为.webp格式
import mlImg from '../assets/images/ML.webp';
import pxweImg from '../assets/images/PXWE.webp';
import ablImg from '../assets/images/ABL.webp';
import ckImg from '../assets/images/CK.webp';
import mdldImg from '../assets/images/MDLD.webp';
import mgnImg from '../assets/images/MGN.webp';
import zc2Img from '../assets/images/ZC2.webp';
import zyImg from '../assets/images/ZY.webp';
import zcImg from '../assets/images/ZC.webp';
import backImg from '../assets/images/back.webp';

// 假设这是你的图片路径映射
// 假设我们有一个简单的角色到图片的映射
const characterImages = {
  ml: mlImg,
  zc: zcImg,
  pxwe: pxweImg,
  back: backImg,
  abl: ablImg,
  ck: ckImg,
  mdld: mdldImg,
  mgn: mgnImg,
  zc2: zc2Img,
  zy: zyImg
  // 添加更多角色及其图片
};

function Card({ character }) {
  const [flipped, setFlipped] = useState(false);
  const cardRef = useRef(null); // 创建ref
  const cardEffectRef = useRef(null); // 创建ref

  useThreeScene(cardEffectRef, flipped); // 使用Three.js场景钩子
  const image = characterImages[character.role] || characterImages['zc'];

  const toggleFlip = () => {
    setFlipped(!flipped); // 翻转卡片
    // 这里可以添加其他控制粒子效果的逻辑
    if (!flipped) {
      // 如果卡片未翻转，计算并应用放大及居中样式
      const cardRect = cardRef.current.getBoundingClientRect();
      const centerX = (window.innerWidth - cardRect.width) / 2;
      const centerY = (window.innerHeight - cardRect.height) / 2;
      const offsetX = centerX - cardRect.left;
      const offsetY = centerY - cardRect.top;

      cardRef.current.style.zIndex = 10;
      cardRef.current.style.transition = 'transform 1s';
      cardRef.current.style.transform = `translate(${offsetX}px, ${offsetY}px) scale(2)`;
    } else {
      // 如果卡片已翻转，移除放大及居中样式，恢复原位
      cardRef.current.style.transition = 'transform 1s';
      cardRef.current.style.transform = 'none';
      setTimeout(() => cardRef.current.style.zIndex = 1, 600);
    }
  };

  const parts = character.ability.split('|').reduce((acc, part, index, array) => {
    if (index % 2 === 0) {
      // 偶数索引，不在|之间的文本
      if (part) acc.push({ text: part, isHighlighted: false });
      // 检查是否有后续文本需要以红色高亮显示
      if (index + 1 < array.length) {
        acc.push({ text: array[index + 1], isHighlighted: true });
      }
    }
    return acc;
  }, []);
  const abilityWithBreaks = (str) => str.split(' ').map((part, index) => (
    <React.Fragment key={index}>
      {index > 0 && <br />}
      {part}
    </React.Fragment>
  ));
  return (
    <>
      <div ref={cardRef} className="card-container" onClick={toggleFlip}>
        <div className={`card ${flipped ? 'is-flipped' : ''}`}>
          <div className="card-front" style={{ backgroundImage: `url(${backImg})` }}></div>
          <div className="card-back" style={{ backgroundImage: `url(${image})` }}>
            <div ref={cardEffectRef} className="card-effect">
              <div className="card-border position-abs vertical" />
              <div className="card-desc position-abs vertical">
                <div className="card-name">{character.name.split('').map(word => (<>{word}<br /></>))}</div>
                <div className="card-ability">{parts.map((part, index) => (
                  <span key={index} style={part.isHighlighted ? { color: '#e3ab21' } : {}}>
                  {abilityWithBreaks(part.text)}
                </span>
                ))}</div>
              </div>
            </div>
            {/* 将ref附加到要渲染Three.js场景的DOM元素 */}
          </div>
        </div>
      </div>
      <div className={flipped ? 'fullscreen-overlay' : ''} onClick={toggleFlip}/>
    </>
  );
}

export default Card;
